@use "./variables" as *;
@use "./mixins" as *;

// Stripping Styles
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  //font-family: $mainFont;
}

// Base Styles
html {
  scroll-behavior: smooth;
  background-color: $secondaryColour; 
}

.wrapper {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
}

h1 {
  width: 70%;
}

h3 {
  margin: 5px 0px;
}



  button {
    min-width: 115px;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    transition: all .3s;
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primaryColour;
      border-radius: 5px;
      z-index: -2;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($primaryColour, 10%);
      transition: all .3s;
      border-radius: 5px;
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }


.container {
  background-color: $whiteColour;
  border-radius: 20px;
  padding: 0px 20px;
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
  transition: all .3s cubic-bezier(0,0,.5,1);
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;
    transform: scale(1.01);
  }
}