@use "./variables" as *;
@use "./mixins" as *;


.book {
  
  @include flex(column, center, center);
  h2 {
    height: 35vh;
    width: 100%;
    @include flex(row, center, center);
    background-image: url("../../../assets/headerImageCloudsDark.jpg");
   
  }
 
  .sectionContent {
    @include flex(row, space-evenly, flex-start);
    padding: 100px 0;
    margin: 40px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: $secondaryColour;

    @include tablet {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0px;
      box-shadow: none;
      padding: 0px;
    }
  }

  .getInTouch {
    @include flex(column, normal, flex-start);
    width: 40%;
    @include tablet {
      width: 95%;
    }

    button {
      width: 40%;
    }
  }

  h3 {
    width: 100%;
  }

  p {
    width: 90%;
  }

  .sectionItems {
    @include flex(column, center, center);
    height: 60vh;
    align-content: center;
    gap: 20px;
    width: 50%;
  }

  form {
    button {
      width: 40%;
    }

    label {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      outline: 0;
      border: 0.5px solid black;
      border-radius: 10px;
      background: transparent;
    }

    .formItem {
      margin-bottom: 15px;
      position: relative;
    }
  }
}

.formContainer {
  @include flex(column);
  width: 40%;

  @include tablet {
    width: 95%;
  }

  p {
    margin: 15px 0;
  }
}

.billingPolicies {
  @include flex(column, normal, center);
  width: 100%;
  margin: 0;

  @include tablet {
    margin-left: 10px;
  }
}
