@use "./variables" as *;
@use "./mixins" as *;

nav {
    padding-top: 10px;
    background-color: $primaryColour;
    position: fixed;
    z-index: 100;
    top: 0px;
    right: 0px;
    left: 0px;
 
    .navWrapper {
        margin: 0 auto;
        max-width: 1600px;
        display: flex;
        justify-content: space-between;
    }
    
    .navIcon {
        background-color: $primaryColour;
        i {
            color: $tertiaryColour;
        }
       &:before {
    background-color: $primaryColour;
       }
       &:after {
        background-color: $primaryColour;;
       }
        
        display: none;
        min-width: 35px;
        width: 50px;
        margin: 5px 20px;
        z-index: 1;
        @include tabletSmall {
            display: block;
        }
    }
    .logoContainer {
        margin: 5px 30px;
        width: 120px;
        padding-top: 0px;
        @include mobile {
            width: 100px;
        }
        .logo {
            width: 100%;
            height: 100%;
        }
    }   
    .navBar {
        display: flex;
        justify-content: space-between;
        padding: 10px 50px;
        align-items: center;
       
        ul {
            gap: 50px;
            @include flex(row, center);
            transition: all 1s;
    
            li {
                width: max-content;
            }
    
            a {
                &:hover {
                    border-bottom: 4px solid $quarternaryColour;
                }
            }
        }
        @include tabletSmall {
            display: none;
        }
    }
}

// Slide Out Nav Styles:
.slideOutNav {
    width: 100%;
    height: 100vh;
    opacity: 0.97;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    ul {
        height: 100vh;
        @include flex (column, center, center);
    }
    li {
        padding-bottom: 30px;
    }
    a {
        font-size: 2.5rem;
        line-height: 3rem;
        &:hover {
            color: $tertiaryColour;
        }
    }
    .navCloseIcon {
        background-color: $primaryColour;
        i {
            color: $tertiaryColour;
        }
        &:after{
            background-color: $primaryColour;
        }
        &:before {
            background-color: $primaryColour;
        }
        display: none;
        position: absolute;
        top: 10px;
        right: 15px;
        min-width: 35px;
        width: 50px;
        z-index: 1;
        @include tabletSmall {
            display: block;
        }
    }
}

// Show and Hide class to toggle using Javascript (for slide-out Nav)
.show {
    display: block;
}

.hide {
    display: none;
}