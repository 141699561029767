@use "variables" as *;

// Mixin for flexBox
@mixin flex ($direction: row, $mainAxis: normal, $crossAxis: normal, $wrap: nowrap) {
    display: flex;
    flex-direction: $direction;
    justify-content: $mainAxis;
    align-items: $crossAxis;
    flex-wrap: $wrap;
}

@mixin laptop {
  @media (max-width: 1050px) {
    @content;
  }
}

@mixin tablet {
    @media (max-width: 900px) {
      @content;
    }
  }

@mixin tabletSmall {
  @media (max-width: 780px) {
    @content;
  }
}
  
@mixin mobile {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin mobileLarge {
  @media (max-width: 526px) {
    @content;
  }
}
  