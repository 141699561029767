@use "variables" as *;
@use "mixins" as *;

body {
    font-family: $mainFont;
    /* 20px default font size @ 125% */
    color: $fontColour;
}

html {
     font-size: 62.5%;
}

h1 {
    // from landing page
    font-size: clamp(3.125rem, 0.4167rem + 8.6667vw, 8rem);
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 0px;
}

h2 {
    // from landing page
    font-size: clamp(1.875rem, 0.1389rem + 5.5556vw, 5rem);
    text-align: center;
    // from landing page
    text-transform: uppercase;
   
}


h3 {
    // from global styles
    font-size: clamp(1.25rem, 0.9028rem + 1.1111vw, 1.875rem);
    // from landing page 
    
    // from bookings page
    // font-size: clamp(3rem, 3vw, 4rem);
    // text-align: left;
    // from policy page
    // font-size: clamp(1.5rem, 2.3vw, 2.5rem);
}

.bio h4 {
    font-size: clamp(1.6rem, 0.8611rem + 0.4444vw, 2rem);
    display: inline;

    @include tablet {
        width: 30%;
    }
}

.bio h3 {
     font-size: clamp(2.4rem, -0.4844rem + 4.75vw, 3rem);
     margin: 25px 0px;
}

.getInTouch h3 {
    font-size: clamp(4.5rem, -0.4844rem + 4.75vw, 4rem); 

    @include tablet {
        margin-bottom: 75px;
    }
}

h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
}

h5 {
    font-size: clamp(1.4rem, 0.8611rem + 0.4444vw, 2rem);
    text-transform: uppercase;
    font-weight: 500;
}

p {
    
    font-size: clamp(1.5rem, 0.8611rem + 0.4444vw, 2rem);
  
}

.about p {
      line-height: clamp(2rem, 2.3vw, 2.2rem);
}

.headline p {
    font-size: clamp(1.6rem, -0.4844rem + 4.75vw, 2.1875rem);
    
}

.policy {
    p {
        font-size: clamp(1.4rem, 1.5vw, 1.7rem);
        line-height: clamp(2rem, 2.3vw, 2.2rem);
    }

    h3 {
        font-size: clamp(1.6rem, 1.5vw, 2.2rem);
        color: darken($primaryColour, 15%);
    }
    li {
        list-style: disc;
    }
    .address li {
        list-style: none;
        line-height: clamp(1.5rem, 2vw, 2rem);
    }
}

ol, ul {
    font-size: clamp(1rem, 0.8611rem + 0.4444vw, 2rem);
    line-height: clamp(1.8rem, 2.3vw, 2.5rem);
}

li {
    // from landing page
    font-size: clamp(1.5rem, 0.8611rem + 0.4444vw, 2rem);
}

footer p, footer li {
    font-size: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
    line-height: clamp(1.35rem, 2vw, 1.75rem)
}

a {
    // from global styles
    font-size: clamp(1rem, 0.8611rem + 0.4444vw, 2rem);
    color: $fontColour;
}

nav a {
    font-size: 1.8rem;
    font-weight: 600;
}

nav i {
    font-size: clamp(3.25rem, 6vw, 3.5rem)
}

.slideOutNav i {
    font-size: clamp(3.25rem, 6vw, 3.5rem)
}

i {
    font-size: clamp(1.25rem, 0.2083rem + 3.3333vw, 3.125rem);
    color: $primaryColour;
}  

footer i {
    font-size: clamp(1.25rem, 0.2083rem + 3.3333vw, 4.125rem);
}

.service i {
    font-size: 4rem;
}


.approach i, .qualifications i {
    font-size: clamp(1rem, 0.8611rem + 0.4444vw, 1.5rem);
}

span {
    color: $fontColour;
    font-weight: 700;
}

button {
    // From GlobalStyles
    font-size: clamp(1.4rem, 0.8611rem + 0.4444vw, 2rem);
    // From landing Page
    // font-size: clamp(1.25rem, 0.8333rem + 1.3333vw, 2rem);
    // from bookings page
    // font-size: 2rem;
}

input, textarea {
    font-size: 1.35rem;
    font-family: 'Nunito';
}

.centered {
    text-align: center;
}

.bold {
    font-weight: 700;
}

.underline {
    text-decoration: underline;
}