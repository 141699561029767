@use "./variables" as *;
@use "./mixins" as *;

.policy {
    .headerImg {
        @include flex(row, center, center);
        height: 35vh;
        background-image: url("../../../assets/headerImageCloudsDark.jpg");
    }
    .page {
        background-color: #F2F2F3;
        border-radius: 20px;
        box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
        transition: all .3s cubic-bezier(0, 0, .5, 1);
        margin: 60px auto;
        width: 80%;
        max-width: 1500px;
        padding: 30px 60px;
        @include tabletSmall {
            width: 100%;
            box-shadow: none;
            border-radius: 0px;
            margin: 0px;
        }
    }
    h3 {
        margin: 25px 0 10px;
    }
    p {
        margin-bottom: 10px; 
    }
    li {
        margin-left: 20px;
        padding-left: 5px;
    }
    .address {
        text-align: center;
        padding: 0px;
        li {
            margin: 0;
            padding: 0;
        }
    }
}