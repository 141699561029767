@use "./variables" as *;
@use "./mixins" as *;

footer {
   
    margin-top: 50px;
    background-color: $primaryColour;
    //background-color:  #bfccd9;
    background-size: cover;
    .bookingFooterContent {
        padding-top: 45px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        section {
            @include flex(row, space-between);
            width: 80%;
            margin-top: 35px;
            .crisisSupport {
                @include flex(column);
                width: 60%;
                margin-right: 20px;
                p {
                    margin-top: 10px;
                }
                li {
                    margin-top: 3px;
                    list-style-type: disc;
                    margin-left: 25px;
                }
                @include laptop {
                    width: 85%;
                }
            }
            .buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 30%;
                margin-top: 10px;
               
                button {
                    width: 100%;
                    background-color: white;
                    border: 1px solid white;
                    &::before {
                        background-color: white;
                    }
                    &::after {
                        background-color: darken(white, 5%);
                    }
                    @include laptop {
                        width: 47%;
                    }
                }
                @include laptop {
                    @include flex (row, center, center, wrap);
                    width: 100%;
                }
            }
            .socialMedia {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 25px;
                padding-top: 20px;
                border-top: 3px solid $secondaryColour;
                .sx-verified-seal {
                    width: 40%;
                }
                img {
                    border-radius: 20px;
                    width: 100%;
                }
                i {
                    margin-left: 10px;
                    color: darken($primaryColour, 15%);
                }
                @include laptop {
                    @include flex (row, flex-start, center);
                    padding-top: 10px;
                    margin-top: 10px;
                    .sx-verified-seal {
                        width: 50%;
                    }   
                }
                @include tabletSmall {
                    width: 100%;
                    border: none;
                    @include flex (row, center, center);
                    .small {
                        font-size: 4rem;
                    }
                    .sx-verified-seal {
                        width: 22%;
                    }
                }
            }
            @include laptop {
                @include flex (column, flex-start, center)
            }
            @include tabletSmall {
                width: 100%;
                margin-top: 10px;
            }
        }
    
        form {
            width: calc(50% - 50px);
            margin-right: 50px;
            h4 {
                width: 100%;
                margin-bottom: 0px;
            }
            input, textarea {
                border-radius: 10px;
                background-color: $secondaryColour;
                padding: 0px 10px;
            }
            input {
                width: calc(50% - 10px);
                margin: 15px 10px 10px 0;
                padding: 10px;
                border-radius: 10px;
                font-size: 1.35rem;
                font-family: 'Nunito';
                border: none;
                outline: none;
                background-color: rgba(255, 255, 255, 0.461);
                color: $fontColour;
            }
            textarea {
                width: calc(100% - 10px);
                height: 150px;
                padding: 10px;
                border: none;
                outline: none;
                font-size: 1.35rem;
                font-family: 'Nunito';
                background-color: rgba(255, 255, 255, 0.461);
                color: $fontColour;
                @include laptop {
                    height: 200px;
                }
                @include tabletSmall {
                    height: 130px;
                }
            }
            button {
                margin-top: 15px;
                padding: 10px 15px;
                font-family: $mainFont;
                color: $fontColour;
                &::after {
                    background-color: darken($primaryColour, 5%);
                }
                &:hover{
                    color: $fontColour;
                }
            }
            @include tabletSmall {
                width: 100%;
            }
        }
        @include tablet {
            padding-top: 0px;
        }
        @include tabletSmall {
            @include flex (column, center, flex-start);
            padding: 50px 0 40px;
        }
    }
    .builtBy {
        border-top: solid #00000059 1px;
        margin-top: 30px;
        padding: 10px 0px;
        text-align: center; 
        font-size: clamp(1.4rem, 0.5vw, 1.5rem);
        a {
            font-size: clamp(1.4rem, 0.5vw, 1.5rem);
            line-height: clamp(1.9rem, 0.5vw, 2rem);
            font-weight: 600;
        }
        @include tabletSmall {
            margin-top: 0px;
        }
    }
    @include tabletSmall {
        margin-top: 0px;
    }
}