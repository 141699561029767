@use "./variables" as *;
@use "./mixins" as *;

.faq {
  .header {
    background-image: url("../../../assets/headerImageCloudsDark.jpg");
    display: flex;
    justify-content: center;
    height: 35vh;
    h2 {
    width: 80%;
    text-align: center;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    
   
  }
  }
  

  .faqList {
    @include flex(column, space-between);
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .faqItem {
    @include flex(column);
    margin-bottom: 20px;
    background-color: $tertiaryColour;
    padding: 25px 50px;  

    @include tabletSmall {
      padding: 25px 20px
    }
    box-shadow: $listShadow;
    &:hover {
      cursor: pointer;
    }
  }
   
  .answer {
    @include flex(row, center);
    p {
      width: 94%;
      margin-top: 10px;
    }
  }

  .question {
    gap: 10px;
    @include flex(row, space-between, center);
    i {
      font-size: clamp(1.125rem, 0.7083rem + 1.3333vw, 2.3rem); 
      color: $primaryColour;
    }
   
  }
}
