@use "./variables" as *;
@use "./mixins" as *;

header {
  background-image: url("../../../assets/headerImageCloudsDark.jpg");
  background-position-y: 20%;
  @include tablet{
    background-position-y: 20%;
    background-position-x: 50%;
  }
  @include mobileLarge {
    background-position-y: 25%;
    background-position-x: 55%;
  }

  .headerContent {
    margin: 0 auto;
    max-width: 1200px;
    @include flex(column, center, left);
    height: 80vh;

    p {
      font-size: clamp(1.25rem, -1.0938rem + 7.5vw, 3.125rem);
      margin-bottom: 20px;
      font-style: italic;
    }

    @include tablet {
      height: 70vh;
      margin-left: 30px;
      margin-top: 0px;

      h1 {
        width: 80%;
      }
    }

    p {
      margin-bottom: 20px;
      width: 90%;
    }
    button {
      background-color: $quarternaryColour;
      width: 15%;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
      color: #fff;
      min-width: 115px;

      &:after {
        content: "";

        background-color: $quarternaryColour;
      }
      &:before {
        content: "";

        background-color: darken($quarternaryColour, 7%);
      }
      &:hover {
        color: #fff;
       
      }
    }
  }
}

// landing page banner

.headline {
  @include flex(row, center, center);
  height: 25vh;

  @include tablet {
    height: 35vh;
  }

  p {
    width: 80%;
  }
}

// services section here

.offering {
  @include flex(column, space-evenly);
  min-height: 90vh;
  align-items: center;
  

  h2 {
    margin: 40px;
  }

}

.services {
  @include flex(row, space-evenly, center);
  min-height: 50vh;
  
  @include tablet {
    @include flex(column, space-evenly, center)
  }

  .service  {
    padding: 0px 45px;
    @include flex(row, center, center);
    width: calc(33% - 20px);
    max-width: 375px;
    margin: 0px 10px;
    min-height: 50vh;
    animation: slideUp 2s;
    transition: all .3s cubic-bezier(0,0,.5,1);
    &:hover {
      box-shadow: rgba(41, 41, 57, 0.25) 0px 6px 8px -2px;
      transform: scale(1.04);
    }
    
    @include tablet {
      width: 100%;
      margin: 10px 0px;
    }

    @include mobile {
      width: 80%;
    }


    

    div {
      @include flex(column, space-evenly, center);
      height: 30vh;
      margin: 0 10px;
    }

    button {
      width: 50%;
      color: black;
    }
  }
}

//about section styles begin here

.about {
  @include flex(row, space-evenly, center);
  padding: 25px 0px;
  min-height: 80vh;
  background-color: $tertiaryColour;

  @include tablet {
    flex-direction: column;
  }

  // CHANGE THIS TO A DROPDOWN SECTION -- CREATE DROPDOWN IN GLOBAL STYLES
  .approach,
  .qualifications {
    @include flex(row, space-between, center);
    background-color: $whiteColour;
    padding: 20px 30px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 2px 0px 12px rgb(0 0 0 / 8%);
    transition: all .3s cubic-bezier(0,0,.5,1);
    &:hover {
      box-shadow: rgba(41, 41, 57, 0.25) 0px 6px 8px -2px;
      transform: scale(1.01);
    }
  }

  .qualsList {
     padding-left: 30px;
     margin-bottom: 20px;
      li {
    list-style-type: circle;
  }  
}
  
  .bio {
    width: 50%;

    @include tablet {
      width: 90%;
    }

    h4 {
      border-bottom: 2px solid $quarternaryColour;
      width: 26%;
      margin-bottom: 20px;
    }
    p {
      margin: 15px 0;
    }
  }

  .headshot {
    width: 35%;
    display: flex;
    justify-content: center;
    @include tablet {
      width: 60%;
    }
    @include mobileLarge {
      width: 100%;
    }
    img {
      width: 90%;
      border-radius: 14px;
    }
  }
}

//keyframes for animations here
@keyframes slideUp {
  0% {
    transform: translate(0, 300px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

// header img on services component

.servicesComponent {
  .headerImg {
    @include flex(row, center, center);
    height: 35vh;
    background-image: url("../../../assets/headerImageCloudsDark.jpg");
  }
  .services {
    margin: 100px auto;
  }
}
